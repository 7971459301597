import { notification } from 'antd';
import { DEFAULT_LIMIT_SIZE, NOTIFY_TYPE, TYPE_SOLUTION_PROJECT } from '../constants/enums';
import _ from 'lodash';
import { TYPE_MODULE_NAME_ECO_SALES } from '../constants/string';
import {
  BACKGROUND_CHART,
  KEY_EQUIPMENT_ENERGY,
  KEY_EQUIPMENT_MATERIALS,
  KEY_OPERATION,
  KEY_SHIPPING,
  MapKeyFactor,
  NAME_MODULES_COMPARE,
  TYPE_FACTOR,
} from 'src/pages/Result/const';
import { UNIT } from '../constants/unit';

export const getCookieValue = (key: any) => {
  const allCookies = document.cookie;
  let storedValue = '';
  if (allCookies) {
    const cookieArray = allCookies.split(';');
    const filteredCookie = cookieArray.filter((element: any) => {
      const singleCookie = element.split('=');
      if (singleCookie[0].trim() === key) {
        storedValue = singleCookie[1];
      }
      return storedValue;
    });
  }
  return storedValue;
};

export const compareData = (obj1: any, obj2: any) => {
  if (obj1 === null && obj2 !== null) {
    return false;
  }
  const obj1Length = Object.keys(obj1).length;
  const obj2Length = Object.keys(obj2).length;

  if (obj1Length === obj2Length) {
    return Object.keys(obj1).every((key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]);
  }
  return false;
};

/**
 * Compare whether two objects are different or not
 * @param obj1
 * @param obj2
 */
export const compareObjects = (obj1: Record<string, any>, obj2: Record<string, any>) => {
  // The data may not be of the same type. Convert data to the same string type for comparison.
  function convertToString(value: any): string {
    if (typeof value === 'number') {
      return value.toString();
    }
    if (value && (typeof value === 'object' || Array.isArray(value))) {
      return JSON.stringify(value);
    }
    return value;
  }

  // Compare two objects
  for (const key of Object.keys({ ...obj1, ...obj2 })) {
    const value1 = obj1[key];
    const value2 = obj2[key];

    const value1Str = convertToString(value1);
    const value2Str = convertToString(value2);

    if (value1Str !== value2Str) {
      return false;
    }
  }
  // Returns true if the two objects are the same and vice versa
  return true;
};

export const calculatorDistance = (obj1: any, obj2: any) => {
  if (obj1 && obj2) {
    var R = 6371; // Radius of the earth in km
    let lat1 = (Math.PI / 180) * obj1?.lat;
    let lat2 = (Math.PI / 180) * obj2?.lat;
    let lon1 = (Math.PI / 180) * obj1?.lng;
    let lon2 = (Math.PI / 180) * obj2?.lng;

    let d = Math.acos(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon1 - lon2));
    var dc = R * d; // Distance in km
    return dc;
  } else {
    return 0;
  }
};

export const checkInputFloat = (value: string) => {
  if (/^[+-]?\d+(\.\d+)?$/.test(value) || /^[+-]?\d+(\.)$/.test(value) || value == '') {
    return true;
  } else {
    return false;
  }
};

export const downloadCsvUtil = (contentData: any, name: string) => {
  contentData.forEach((e: any) => {
    for (let i = 0; i < e.length; i++) {
      if (typeof e[i] == 'string') {
        e[i] = e[i].replaceAll(',', ' ');
      }
    }
  });
  let csvContent = 'data:text/csv;charset=utf-8,' + contentData.map((e: any) => e.join(',')).join('\n');
  var encodedUri = encodeURI(csvContent);
  var link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', name + '_' + new Date().toISOString().slice(0, 19) + '.csv');
  document.body.appendChild(link); // Required for FF

  link.click();
};

export const formatNumberSeparators = (data: any) => {
  if (data) {
    if (typeof data == 'number') {
      return parseFloat(data.toFixed(2)).toLocaleString();
    } else {
      return parseFloat(parseFloat(data).toFixed(2)).toLocaleString();
    }
  } else {
    return '0';
  }
};
export const formatNumberSeparatorsDefault = (data: any) => {
  if (data) {
    if (Number(data) < 1) {
      return data;
    }
    if (typeof data == 'number') {
      return parseFloat(data.toFixed(2)).toLocaleString();
    } else {
      return parseFloat(parseFloat(data).toFixed(2)).toLocaleString();
    }
  } else {
    return '0';
  }
};

export const formatNumberWithCommas = (x: any) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const handleShortenTheDigitAfterDecimalPlaces = (data: any) => {
  if (data === 0) {
    return data;
  }

  if (data) {
    const v = (typeof data === 'string' ? data : data.toString()).split('.');
    let f = v[1] || '';
    if (v.length === 1) {
      return data;
    } else if (v.length > 1) {
      return f.length < 3 ? `${v[0]}.${f.substr(0, 2)}` : `${v[0]}.${f.substr(0, 2)}` + '...';
    } else {
      return data;
    }
  } else {
    return '';
  }
};

/**
 * Open notification dialog
 * @param config
 * @param type
 */
export const OpenToast = (config: IToastConfig, type: string, className?: string) => {
  notification.config({
    top: 80,
    className,
  });

  switch (type) {
    case NOTIFY_TYPE.SUCCESS:
      notification.success(config);
      break;
    case NOTIFY_TYPE.ERROR:
      notification.error(config);
      break;
    case NOTIFY_TYPE.INFO:
      notification.info(config);
      break;
    case NOTIFY_TYPE.WARNING:
      notification.warning(config);
      break;
    default:
      notification.open(config);
      break;
  }
};

/**
 *Check file size upload limit size
 * @param files
 * @returns
 */
export const checkFileSizeLimit = (files: any): boolean => {
  let size = 0;
  if (Array.isArray(files)) {
    for (const file of files) {
      size += file.size / (1024 * 1024);
    }
  }
  if (files instanceof File) {
    size += files.size / (1024 * 1024);
  }
  if (size > DEFAULT_LIMIT_SIZE) {
    return true;
  }
  return false;
};

export const getFileExtFromFileName = (fileName: string): string => {
  let ext = fileName.split('.').pop() ?? '';
  return ext;
};

// Same debounce func from lodash
export const debounce = (func: any, delay: number) => {
  let timerId: any = '';

  return (...args: any[]) => {
    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

/**
 * Delete obj keys and reset keys
 * @param objWithKeysNumber:
 */
export const deleteAndResetObjectKeys = (object: any, key: number) => {
  delete object[key];
  while (++key in object) {
    object[key - 1] = object[key];
    delete object[key];
  }
};

/**
 * Round carbon emisssions
 * @param carbon // kg,
 * @return newCarbon && hover number // tons,kg
 */
export const roundCarbon = (carbon: number) => {
  if (carbon < 5) return { newCarbon: 0, hoverNum: { data: Number(carbon.toFixed(2)), showKg: true } };
  else if (5 <= carbon && carbon < 14.5) return { newCarbon: 0.01, hoverNum: { data: Number(carbon.toFixed(2)), showKg: true } };
  else if (14.5 <= carbon && carbon < 1000)
    return { newCarbon: Number((carbon / 1000).toFixed(2)), hoverNum: { data: Number(carbon.toFixed(2)), showKg: true } };
  else return { newCarbon: Number((carbon / 1000).toFixed(2)), hoverNum: { data: Number(carbon.toFixed(2)), showKg: true } };
};

/**
 * Create mock OT breakdown
 * @param year,
 * @return
 */
export const makeMockOtBdData = (year: number, arrLength = 3) => {
  const data = new Array(arrLength)
    .fill('')
    .map((_, i) => ({
      label: year - (i + 1),
      businessUnits: [],
    }))
    .sort((a, b) => a.label - b.label);
  return data;
};

export const getKeyByValue = (targetValue: any, obj: any) => {
  return _.findKey(obj, (value) => value === targetValue);
};

export const formatNumber = (number: any) => {
  number += '';
  const splitDots = number.split('.');
  let numberOne = splitDots[0];
  let numberTwo = splitDots.length > 1 ? '.' + splitDots[1] : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(numberOne)) {
    numberOne = numberOne.replace(rgx, '$1' + ' ' + '$2');
  }
  return numberOne + numberTwo;
};

export const renderClassNameSubChart = (nameModule: string) => {
  let className = '';
  switch (nameModule) {
    case TYPE_MODULE_NAME_ECO_SALES.EQUIPMENT_MATERIAL:
      className = 'chart-detail-percent-material';
      break;
    case TYPE_MODULE_NAME_ECO_SALES.EQUIPMENT_ENERGY:
      className = 'chart-detail-percent-energy';
      break;
    case TYPE_MODULE_NAME_ECO_SALES.SHIPPING:
      className = 'chart-detail-percent-shipping';
      break;
    case TYPE_MODULE_NAME_ECO_SALES.OPERATION:
      className = 'chart-detail-percent-operation';
      break;
  }
  return className;
};

export const renderClassNameMainChart = (nameModule: string) => {
  let className = '';
  switch (nameModule) {
    case TYPE_MODULE_NAME_ECO_SALES.EQUIPMENT_MATERIAL:
      className = 'html-bar-material';
      break;
    case TYPE_MODULE_NAME_ECO_SALES.EQUIPMENT_ENERGY:
      className = 'html-bar-energy';
      break;
    case TYPE_MODULE_NAME_ECO_SALES.SHIPPING:
      className = 'html-bar-shipping';
      break;
    case TYPE_MODULE_NAME_ECO_SALES.OPERATION:
      className = 'html-bar-operation';
      break;
  }
  return className;
};

export const renderBackgroundChartGlobalWarming = (nameModule: string) => {
  let backgroundColor = '';
  switch (nameModule) {
    case NAME_MODULES_COMPARE.material:
      backgroundColor = BACKGROUND_CHART.equipmentMaterials;
      break;
    case NAME_MODULES_COMPARE.energy:
      backgroundColor = BACKGROUND_CHART.equipmentManufacturingEnergy;
      break;
    case NAME_MODULES_COMPARE.shipping:
      backgroundColor = BACKGROUND_CHART.shipping;
      break;
    case NAME_MODULES_COMPARE.operation:
      backgroundColor = BACKGROUND_CHART.operation;
      break;
  }
  return backgroundColor;
};

export const renderUnitOfChartFactor = (factor: string) => {
  let unit = '';
  switch (factor) {
    case TYPE_FACTOR.Freshwater:
    case TYPE_FACTOR.Marine:
    case TYPE_FACTOR.Terrestric:
    case TYPE_FACTOR.Human:
      unit = `${UNIT.UNIT_DCB}${UNIT.UNIT_EQ}`;
      break;
    case TYPE_FACTOR.Eutrophication:
      unit = `${UNIT.UNIT_PHOSPHATE}${UNIT.UNIT_EQ}`;
      break;
    case TYPE_FACTOR.Ozone:
      unit = `${UNIT.UNIT_R11}${UNIT.UNIT_EQ}`;
      break;
    case TYPE_FACTOR.Photochemical:
      unit = `${UNIT.UNIT_ETHENE}${UNIT.UNIT_EQ}`;
      break;
  }
  return unit;
};

export const renderUnitOfFactor = (keyFactor: string) => {
  let unit = '';
  switch (keyFactor) {
    case MapKeyFactor[TYPE_FACTOR.GlobalWarmingPotential]:
      unit = `${UNIT.UNIT_CO}`;
      break;
    case MapKeyFactor[TYPE_FACTOR.Acidification]:
      unit = `${UNIT.UNIT_SO}`;
      break;
    case MapKeyFactor[TYPE_FACTOR.Freshwater]:
    case MapKeyFactor[TYPE_FACTOR.Marine]:
    case MapKeyFactor[TYPE_FACTOR.Terrestric]:
    case MapKeyFactor[TYPE_FACTOR.Human]:
      unit = `${UNIT.UNIT_DCB}`;
      break;
    case MapKeyFactor[TYPE_FACTOR.Photochemical]:
      unit = `${UNIT.UNIT_ETHENE}`;
      break;
    case MapKeyFactor[TYPE_FACTOR.Ozone]:
      unit = `${UNIT.UNIT_R11}`;
      break;
    case MapKeyFactor[TYPE_FACTOR.Eutrophication]:
      unit = `${UNIT.UNIT_PHOSPHATE}`;
      break;
  }
  return unit;
};
export const formatNumberHighestMillion = (number: any) => {
  if (number < 1e6) {
    return formatNumber(number);
  } else {
    return +(number / 1e6).toFixed(0) + ' M';
  }
};
export const renderDataTableSummaryColumn = (keyModule: number, dataSummaryColumn: number) => {
  let dataTotalOfModule: number = 0;
  switch (keyModule) {
    case KEY_SHIPPING:
      dataTotalOfModule = dataSummaryColumn;
      break;
    case KEY_EQUIPMENT_ENERGY:
      dataTotalOfModule = dataSummaryColumn;
      break;
    case KEY_EQUIPMENT_MATERIALS:
      dataTotalOfModule = dataSummaryColumn;
      break;
  }
  return dataTotalOfModule;
};

export const naiveRound = (num: any, decimalPlaces = 2) => {
  let p = Math.pow(10, decimalPlaces);
  return Math.round(num * p) / p;
};
export const formatNumberExponential = (num: any) => {
  let number: any = 0;
  if (naiveRound(num, 2) === 0 && num != 0) {
    const parseNumToExponential = num?.toExponential(1);
    const splitNumBeforeExponential = `${parseNumToExponential}`.split('e')[0];
    const splitNumExponential = `${parseNumToExponential}`.split('e')[1];
    // In the case of the first 2 numbers - less than 10
    const splitNumExponentialMinus = `${parseNumToExponential}`.split('e-')[1];
    if (Number(splitNumExponentialMinus) < 10) {
      const splitMinus = `0${splitNumExponential.split('-')[1]}`;
      number = `${splitNumBeforeExponential}E-${splitMinus}`;
    } else {
      number = `${splitNumBeforeExponential}E${splitNumExponential}`;
    }
  } else {
    number = formatNumber(naiveRound(num, 1));
  }
  return number;
};
export const renderCarbonAndUnitOfFactor = (keyFactor: string, CO2eqTons: any, CO2eqKg: any) => {
  let carbon = 0;
  switch (keyFactor) {
    case TYPE_FACTOR.Acidification:
    case TYPE_FACTOR.Eutrophication:
    case TYPE_FACTOR.Terrestric:
    case TYPE_FACTOR.Freshwater:
    case TYPE_FACTOR.Human:
    case TYPE_FACTOR.Marine:
    case TYPE_FACTOR.Ozone:
    case TYPE_FACTOR.Photochemical:
      carbon = formatNumberExponential(CO2eqKg);
      break;
    default:
      carbon = formatNumberExponential(CO2eqTons);
      break;
  }
  return carbon;
};
export const renderCarbonAndUnitOfFactorTable = (keyFactor: string, CO2eqTons: any, CO2eqKg: any) => {
  let carbon = 0;
  switch (keyFactor) {
    case TYPE_FACTOR.Acidification:
    case TYPE_FACTOR.Eutrophication:
    case TYPE_FACTOR.Terrestric:
    case TYPE_FACTOR.Freshwater:
    case TYPE_FACTOR.Human:
    case TYPE_FACTOR.Marine:
    case TYPE_FACTOR.Ozone:
    case TYPE_FACTOR.Photochemical:
      carbon = formatNumberExponentialTableModule(CO2eqKg);
      break;
    default:
      carbon = formatNumberExponentialTableModule(CO2eqTons);
      break;
  }
  return carbon;
};
export const getCityAndCountryFilterResult = (listProject: any[]) => {
  const listCity = listProject.map((el: any) => {
    return {
      id_city: el.eco_city.id,
      city_name: el.eco_city.name,
      id_location: Number(el.eco_city.id_location),
    };
  });
  const listCountry = listProject.map((el: any) => {
    return {
      id_country: el.eco_location.id,
      country_name: el.eco_location.name,
    };
  });
  const objGr = groupCityAndCountryFilterResult(listCity, listCountry);
  return {
    grCity: objGr.grCity,
    grCountry: objGr.grCountry,
  };
};
export const groupCityAndCountryFilterResult = (listCity: any[], listCountry: any[]) => {
  let grCity: any = {};
  let grCountry: any = {};
  listCity.forEach((item: any) => {
    if (!grCity[item.id_city]) {
      grCity[item.id_city] = {
        id_city: item.id_city,
        city_name: item.city_name,
        id_location: item.id_location,
      };
    }
  });
  listCountry.forEach((item: any) => {
    if (!grCountry[item.id_country]) {
      grCountry[item.id_country] = {
        id_country: item.id_country,
        country_name: item.country_name,
      };
    }
  });
  return {
    grCity: Object.values(grCity),
    grCountry: Object.values(grCountry),
  };
};
export const filterProjectsByCriteria = (projects: any, solutions: any, cityIds: any, countryIds: any, years: any) => {
  return projects.filter((project: any) => {
    if (solutions && solutions.length > 0 && !solutions.includes(project.eco_sales_project_solution.id)) {
      return false;
    }
    if (cityIds && cityIds.length > 0 && !cityIds.includes(project.eco_city.id)) {
      return false;
    }
    if (countryIds && countryIds.length > 0 && !countryIds.includes(project.eco_location.id)) {
      return false;
    }
    if (years && years.length > 0 && !years.includes(project.project_starting_year)) {
      return false;
    }
    return true;
  });
};
export const filterProjectsNotSolution = (projects: any, cityIds: any, countryIds: any, years: any) => {
  return projects.filter((project: any) => {
    if (cityIds && cityIds.length > 0 && !cityIds.includes(project.eco_city.id)) {
      return false;
    }
    if (countryIds && countryIds.length > 0 && !countryIds.includes(project.eco_location.id)) {
      return false;
    }
    if (years && years.length > 0 && !years.includes(project.project_starting_year)) {
      return false;
    }
    return true;
  });
};
export const filterProjectsSolution = (projects: any, cityIds: any, countryIds: any, years: any, solutionIds: any) => {
  return projects.filter((project: any) => {
    if (cityIds && cityIds.length > 0 && !cityIds.includes(project.eco_city.id)) {
      return false;
    }
    if (countryIds && countryIds.length > 0 && !countryIds.includes(project.eco_location.id)) {
      return false;
    }
    if (years && years.length > 0 && !years.includes(project.project_starting_year)) {
      return false;
    }
    if (solutionIds && solutionIds.length > 0 && !solutionIds.includes(project.eco_sales_project_solution.id)) return false;
    return true;
  });
};
export const formatNumberExponentialEnvironmental = (num: any) => {
  let number: any = 0;
  if (naiveRound(num, 2) === 0 && num != 0) {
    const parseNumToExponential = num?.toExponential(0);
    const splitNumBeforeExponential = `${parseNumToExponential}`.split('e')[0];
    const splitNumExponential = `${parseNumToExponential}`.split('e')[1];
    // In the case of the first 2 numbers - less than 10
    const splitNumExponentialMinus = `${parseNumToExponential}`.split('e-')[1];
    if (Number(splitNumExponentialMinus) < 10) {
      const splitMinus = `0${splitNumExponential.split('-')[1]}`;
      number = `${splitNumBeforeExponential}E-${splitMinus}`;
    } else {
      number = `${splitNumBeforeExponential}E${splitNumExponential}`;
    }
  } else {
    number = formatNumber(naiveRound(num, 1));
  }
  return number;
};
export const formatTooltipChartDoughnut = (context: any) => {
  let formatDataLabels: any = context;
  if (formatDataLabels === '0') {
    const parseNumToExponential = context?.parsed.toExponential(2);
    const splitNumBeforeExponential = `${parseNumToExponential}`.split('e')[0];
    const splitNumExponential = `${parseNumToExponential}`.split('e')[1];
    // In the case of the first 2 numbers - less than 10
    const splitNumExponentialMinus = `${parseNumToExponential}`.split('e-')[1];
    if (Number(splitNumExponentialMinus) < 10) {
      const splitMinus = `0${splitNumExponential.split('-')[1]}`;
      formatDataLabels = `${splitNumBeforeExponential}E-${splitMinus}`;
    } else {
      formatDataLabels = `${splitNumBeforeExponential}E${splitNumExponential}`;
    }
  } else {
    formatDataLabels = context?.replaceAll(',', ' ').replaceAll(' ', '');
    formatDataLabels = formatNumber(formatDataLabels ? Number(formatDataLabels).toFixed(1) : 0);
    formatDataLabels = formatDataLabels + ' ' + UNIT.UNIT_TCO + '₂' + UNIT.UNIT_EQ;
  }
  return formatDataLabels;
};
export const formatDataLabelsPercent = (value: any, context: any) => {
  const totalCarbon = context?.dataset?.data?.reduce((a: any, b: any) => a + b, 0);
  let percent: any = 0;
  if (value !== undefined) {
    percent = ((value / totalCarbon) * 100).toFixed(0);
  }
  return percent >= 4 ? `${percent}%` : '';
};

const formatNumberTable = (num: any) => {
  const parts = num.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  if (parts.length === 1) {
    return parts[0];
  } else {
    return `${parts[0]}.${(parts[1] + '00').slice(0, 2)}`;
  }
};

export const formatNumberExponentialTableModule = (num: any) => {
  let number: any = 0;
  if (naiveRound(num, 2) === 0 && num != 0) {
    const parseNumToExponential = num?.toExponential(2);
    const splitNumBeforeExponential = `${parseNumToExponential}`.split('e')[0];
    const splitNumExponential = `${parseNumToExponential}`.split('e')[1];
    // In the case of the first 2 numbers - less than 10
    const splitNumExponentialMinus = `${parseNumToExponential}`.split('e-')[1];
    if (Number(splitNumExponentialMinus) < 10) {
      const splitMinus = `0${splitNumExponential.split('-')[1]}`;
      number = `${splitNumBeforeExponential}E-${splitMinus}`;
    } else {
      number = `${splitNumBeforeExponential}E${splitNumExponential}`;
    }
  } else {
    if (Number.isInteger(num)) {
      number = formatNumberTable(num);
    } else {
      number = formatNumberTable(naiveRound(num, 2));
    }
  }
  return number;
};

export const typePowerRating = (solutionName: string) => {
  switch (solutionName) {
    case TYPE_SOLUTION_PROJECT.HdvcGeneration4:
    case TYPE_SOLUTION_PROJECT.HdvcGeneration5:
    case TYPE_SOLUTION_PROJECT.GridEMotion:
      return `(${UNIT.UNIT_KW})`;
    case TYPE_SOLUTION_PROJECT.GridEXpandtm:
    case TYPE_SOLUTION_PROJECT.GisSubstaion:
      return `(${UNIT.UNIT_MVA})`;
    case TYPE_SOLUTION_PROJECT.FixedSeriesCompensation:
    case TYPE_SOLUTION_PROJECT.SVCLightV2250MVAR:
    case TYPE_SOLUTION_PROJECT.SVCLightV3300MVAR:
      return `(${UNIT.UNIT_MVAR})`;
    case TYPE_SOLUTION_PROJECT.SFCLight:
      return `(${UNIT.UNIT_MW})`;
    default:
      return '';
  }
};
